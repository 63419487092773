import React from "react";
import Layout from "../components/layouts/Layout";
import Header from "../components/Header";
import SEO from "../components/SEO";
import "../styles/PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <Layout>
      <SEO path="/privacy-policy/" />
      <Header title="Privacy Policy" />
      <p className="PrivacyPolicy--emphasis">Last updated December 01, 2019</p>
      <div>
        <p>
          Thank you for choosing to be part of our community at Andrico Karoulla
          (“Company”, “we”, “us”, or “our”). We are committed to protecting your
          personal information and your right to privacy. If you have any
          questions or concerns about our policy, or our practices with regards
          to your personal information, please contact us at
          karoulla.andrico@gmail.com.
        </p>
        <p>
          When you visit our website calisthenicsskills.com, and use our
          services, you trust us with your personal information. We take your
          privacy very seriously. In this privacy policy, we seek to explain to
          you in the clearest way possible what information we collect, how we
          use it and what rights you have in relation to it. We hope you take
          some time to read through it carefully, as it is important. If there
          are any terms in this privacy policy that you do not agree with,
          please discontinue use of our Sites and our services.
        </p>
        <p>
          This privacy policy applies to all information collected through our
          website (such as calisthenicsskills.com), and/or any related services,
          sales, marketing or events (we refer to them collectively in this
          privacy policy as the "Services").
        </p>
        <p className="PrivacyPolicy--emphasis">
          Please read this privacy policy carefully as it will help you make
          informed decisions about sharing your personal information with us.
        </p>
        <h3>TABLE OF CONTENTS</h3>
        <ul className="PrivacyPolicy__table-of-contents">
          <li>
            <a href="#1-what-information-do-we-collect">
              1. WHAT INFORMATION DO WE COLLECT?
            </a>
          </li>
          <li>
            <a href="#2-how-do-we-use-your-information">
              2. HOW DO WE USE YOUR INFORMATION?
            </a>
          </li>
          <li>
            <a href="#3-will-your-information-be-shared-with-anyone">
              3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </a>
          </li>
          <li>
            <a href="#4-who-will-your-information-be-shared-with">
              4. WHO WILL YOUR INFORMATION BE SHARED WITH?
            </a>
          </li>
          <li>
            <a href="#5-do-we-use-cookies-and-other-tracking-technologies">
              5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </a>
          </li>
          <li>
            <a href="#6-how-long-do-we-keep-your-information">
              6. HOW LONG DO WE KEEP YOUR INFORMATION?
            </a>
          </li>
          <li>
            <a href="#7-how-do-we-keep-your-information-safe">
              7. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </a>
          </li>
          <li>
            <a href="#8-do-we-collect-information-from-minors">
              8. DO WE COLLECT INFORMATION FROM MINORS?
            </a>
          </li>
          <li>
            <a href="#9-what-are-your-privacy-rights">
              9. WHAT ARE YOUR PRIVACY RIGHTS?
            </a>
          </li>
          <li>
            <a href="#10-data-breach">10. DATA BREACH</a>
          </li>
          <li>
            <a href="#11-controls-for-do-not-track-features">
              11. CONTROLS FOR DO-NOT-TRACK FEATURES
            </a>
          </li>
          <li>
            <a href="#12-do-california-residents-have-specific-privacy-rights">
              12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </a>
          </li>
          <li>
            <a href="#13-do-we-make-updates-to-this-policy">
              13. DO WE MAKE UPDATES TO THIS POLICY?
            </a>
          </li>
          <li>
            <a href="#-how-can-you-contact-us-about-this-policy">
              14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
            </a>
          </li>
        </ul>
        <h3 id="1-what-information-do-we-collect">
          1. WHAT INFORMATION DO WE COLLECT?
        </h3>
        <p className="PrivacyPolicy--emphasis">
          Personal information you disclose to us
        </p>
        <p>
          In Short: We collect personal information that you provide to us such
          as name, address, contact information, passwords and security data,
          and payment information.
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when registering at the Services expressing an interest in obtaining
          information about us or our products and services, when participating
          in activities on the Services or otherwise contacting us.
        </p>
        <p>
          The personal information that we collect depends on the context of
          your interactions with us and the Services, the choices you make and
          the products and features you use. The personal information we collect
          can include the following:
        </p>
        <p>
          Publicly Available Personal Information. We collect first name, maiden
          name, last name, nickname, email addresses, and other similar data.
        </p>
        <p>
          Personal Information Provided by You. We collect app usage, and other
          similar data. Credentials. We collect passwords, password hints, and
          similar security information used for authentication and account
          access.
        </p>
        <p>
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
        </p>
        <p className="PrivacyPolicy--emphasis">
          Information automatically collected
        </p>
        <p>
          In Short: Some information – such as IP address and/or browser and
          device characteristics – is collected automatically when you visit our
          Services.
        </p>
        <p>
          We automatically collect certain information when you visit, use or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
        <p>
          Online Identifiers. We collect devices, applications, tools and
          protocols, such as IP (Internet Protocol) addresses, cookie
          identifiers, or others such as the ones used for analytics and
          marketing, device's geolocation, and other similar data.
        </p>
        <h3 id="2-how-do-we-use-your-information">
          2. HOW DO WE USE YOUR INFORMATION?
        </h3>
        <p>
          In Short: We process your information for purposes based on legitimate
          business interests, the fulfillment of our contract with you,
          compliance with our legal obligations, and/or your consent.
        </p>
        <p>
          We use personal information collected via our Services for a variety
          of business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
        </p>
        <p>We use the information we collect or receive:</p>
        <ul className="PrivacyPolicy__list">
          <li>
            To facilitate account creation and logon process. If you choose to
            link your account with us to a third party account (such as your
            Google or Facebook account), we use the information you allowed us
            to collect from those third parties to facilitate account creation
            and logon process for the performance of the contract.
          </li>
          <li>
            To send you marketing and promotional communications. We and/or our
            third party marketing partners may use the personal information you
            send to us for our marketing purposes, if this is in accordance with
            your marketing preferences. You can opt-out of our marketing emails
            at any time (see the " WHAT ARE YOUR PRIVACY RIGHTS " below).
          </li>
          <li>
            To send administrative information to you. We may use your personal
            information to send you product, service and new feature information
            and/or information about changes to our terms, conditions, and
            policies.
          </li>

          <li>
            To post testimonials. We post testimonials on our Services that may
            contain personal information. Prior to posting a testimonial, we
            will obtain your consent to use your name and testimonial. If you
            wish to update, or delete your testimonial, please contact us at
            karoulla.andrico@gmail.com and be sure to include your name,
            testimonial location, and contact information.
          </li>
          <li>
            Request Feedback. We may use your information to request feedback
            and to contact you about your use of our Services.
          </li>
          <li>
            To enforce our terms, conditions and policies for Business Purposes,
            Legal Reasons and Contractual.
          </li>
          <li>
            To respond to legal requests and prevent harm. If we receive a
            subpoena or other legal request, we may need to inspect the data we
            hold to determine how to respond.
          </li>
          <li>
            To manage user accounts. We may use your information for the
            purposes of managing our account and keeping it in working order.
          </li>
          <li>
            For other Business Purposes. We may use your information for other
            Business Purposes, such as data analysis, identifying usage trends,
            determining the effectiveness of our promotional campaigns and to
            evaluate and improve our Services, products, marketing and your
            experience. We may use and store this information in aggregated and
            anonymized form so that it is not associated with individual end
            users and does not include personal information. We will not use
            identifiable personal information without your consent.
          </li>
        </ul>
        <h3 id="3-will-your-information-be-shared-with-anyone">
          3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
        </h3>
        <p>
          In Short: We only share information with your consent, to comply with
          laws, to provide you with services, to protect your rights, or to
          fulfill business obligations.
        </p>
        <p>We may process or share data based on the following legal basis:</p>
        <ul className="PrivacyPolicy__list">
          <li>
            Consent: We may process your data if you have given us specific
            consent to use your personal information in a specific purpose.
          </li>
          <li>
            Legitimate Interests: We may process your data when it is reasonably
            necessary to achieve our legitimate business interests.
          </li>
          <li>
            Performance of a Contract: Where we have entered into a contract
            with you, we may process your personal information to fulfill the
            terms of our contract.
          </li>

          <li>
            Legal Obligations: We may disclose your information where we are
            legally required to do so in order to comply with applicable law,
            governmental requests, a judicial proceeding, court order, or legal
            process, such as in response to a court order or a subpoena
            (including in response to public authorities to meet national
            security or law enforcement requirements).
          </li>
          <li>
            Vital Interests: We may disclose your information where we believe
            it is necessary to investigate, prevent, or take action regarding
            potential violations of our policies, suspected fraud, situations
            involving potential threats to the safety of any person and illegal
            activities, or as evidence in litigation in which we are involved.
          </li>
        </ul>
        <p>
          More specifically, we may need to process your data or share your
          personal information in the following situations:
        </p>
        <ul className="PrivacyPolicy__list">
          <li>
            Vendors, Consultants and Other Third-Party Service Providers. We may
            share your data with third party vendors, service providers,
            contractors or agents who perform services for us or on our behalf
            and require access to such information to do that work. Examples
            include: payment processing, data analysis, email delivery, hosting
            services, customer service and marketing efforts. We may allow
            selected third parties to use tracking technology on the Services,
            which will enable them to collect data about how you interact with
            the Services over time. This information may be used to, among other
            things, analyze and track data, determine the popularity of certain
            content and better understand online activity. Unless described in
            this Policy, we do not share, sell, rent or trade any of your
            information with third parties for their promotional purposes. We
            have contracts in place with our data processors. This means that
            they cannot do anything with your personal information unless we
            have instructed them to do it. They will not share your personal
            information with any organisation apart from us. They will hold it
            securely and retain it for the period we instruct.
          </li>

          <li>
            Business Transfers. We may share or transfer your information in
            connection with, or during negotiations of, any merger, sale of
            company assets, financing, or acquisition of all or a portion of our
            business to another company.
          </li>
        </ul>
        <h3 id="4-who-will-your-information-be-shared-with">
          4. WHO WILL YOUR INFORMATION BE SHARED WITH?
        </h3>
        <p>
          In Short: We only share information with the following third parties.
        </p>
        <p>
          We only share and disclose your information with the following third
          parties. We have categorized each party so that you may be easily
          understand the purpose of our data collection and processing
          practices. If we have processed your data based on your consent and
          you wish to revoke your consent, please contact us.
        </p>
        <ul className="PrivacyPolicy__list">
          <li>
            Advertising, Direct Marketing, and Lead Generation
            <br /> Google AdSense
          </li>
          <li>
            Affiliate Marketing Programs
            <br />
            Amazon Affiliation - As an Amazon Associate I earn from qualifying
            purchases.
          </li>

          <li>
            Cloud Computing Services
            <br /> Amazon Web Services (AWS)
          </li>
          <li>
            Content Optimization <br /> YouTube video embed
          </li>

          <li>
            Data Backup and Security <br /> aws rds and aws s3
          </li>
          <li>
            Functionality and Infrastructure Optimization <br />
            Amazon Web Services
          </li>
          <li>
            Web and Mobile Analytics <br /> Google Analytics
          </li>
        </ul>
        <h3 id="5-do-we-use-cookies-and-other-tracking-technologies">
          5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </h3>
        <p>
          In Short: We may use cookies and other tracking technologies to
          collect and store your information.
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Policy.
        </p>
        <h3 id="6-how-long-do-we-keep-your-information">
          6. HOW LONG DO WE KEEP YOUR INFORMATION?
        </h3>
        <p>
          In Short: We keep your information for as long as necessary to fulfill
          the purposes outlined in this privacy policy unless otherwise required
          by law.
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy policy, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this policy
          will require us keeping your personal information for longer than the
          period of time in which users have an account with us .
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize it, or, if
          this is not possible (for example, because your personal information
          has been stored in backup archives), then we will securely store your
          personal information and isolate it from any further processing until
          deletion is possible.
        </p>
        <h3 id="7-how-do-we-keep-your-information-safe">
          7. HOW DO WE KEEP YOUR INFORMATION SAFE?
        </h3>
        <p>
          In Short: We aim to protect your personal information through a system
          of organizational and technical security measures.
        </p>
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, please also remember that we cannot guarantee
          that the internet itself is 100% secure. Although we will do our best
          to protect your personal information, transmission of personal
          information to and from our Services is at your own risk. You should
          only access the services within a secure environment.
        </p>
        <h3 id="8-do-we-collect-information-from-minors">
          8. DO WE COLLECT INFORMATION FROM MINORS?
        </h3>
        <p>
          In Short: We do not knowingly collect data from or market to children
          under 18 years of age.
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent’s use of the Services. If we learn
          that personal information from users less than 18 years of age has
          been collected, we will deactivate the account and take reasonable
          measures to promptly delete such data from our records. If you become
          aware of any data we have collected from children under age 18, please
          contact us at karoulla.andrico@gmail.com.
        </p>
        <h3 id="9-what-are-your-privacy-rights">
          9. WHAT ARE YOUR PRIVACY RIGHTS?
        </h3>
        <p>
          In Short: In some regions, such as the European Economic Area, you
          have rights that allow you greater access to and control over your
          personal information. You may review, change, or terminate your
          account at any time.
        </p>
        <p>
          In some regions (like the European Economic Area), you have certain
          rights under applicable data protection laws. These may include the
          right (i) to request access and obtain a copy of your personal
          information, (ii) to request rectification or erasure; (iii) to
          restrict the processing of your personal information; and (iv) if
          applicable, to data portability. In certain circumstances, you may
          also have the right to object to the processing of your personal
          information. To make such a request, please use the contact details
          provided below. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </p>
        <p>
          If we are relying on your consent to process your personal
          information, you have the right to withdraw your consent at any time.
          Please note however that this will not affect the lawfulness of the
          processing before its withdrawal.
        </p>
        <p>
          If you are resident in the European Economic Area and you believe we
          are unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details{" "}
          <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
            here.
          </a>
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at karoulla.andrico@gmail.com.
        </p>
        <p className="PrivacyPolicy--emphasis">Account Information</p>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can: ■ Contact us using
          the contact information provided.
        </p>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, some information may be retained in our files to prevent
          fraud, troubleshoot problems, assist with any investigations, enforce
          our Terms of Use and/or comply with legal requirements.
        </p>

        <p>
          Cookies and similar technologies: Most Web browsers are set to accept
          cookies by default. If you prefer, you can usually choose to set your
          browser to remove cookies and to reject cookies. If you choose to
          remove cookies or reject cookies, this could affect certain features
          or services of our Services. To opt-out of interest-based advertising
          by advertisers on our Services visit{" "}
          <a href="http://www.aboutads.info/choices/">
            http://www.aboutads.info/choices/
          </a>
          .
        </p>
        <p>
          Opting out of email marketing: You can unsubscribe from our marketing
          email list at any time by clicking on the unsubscribe link in the
          emails that we send or by contacting us using the details provided
          below. You will then be removed from the marketing email list –
          however, we will still need to send you service-related emails that
          are necessary for the administration and use of your account. To
          otherwise opt-out, you may contact us using the contact information
          provided.
        </p>
        <h3 id="10-data-breach">10. DATA BREACH</h3>
        <p>
          A privacy breach occurs when there is unauthorized access to or
          collection, use, disclosure or disposal of personal information. You
          will be notified about data breaches when Andrico Karoulla believes
          you are likely to be at risk or serious harm. For example, a data
          breach may be likely to result in serious financial harm or harm to
          your mental or physical well-being. In the event that Andrico Karoulla
          becomes aware of a security breach which has resulted or may result in
          unauthorized access, use or disclosure of personal information Andrico
          Karoulla will promptly investigate the matter and notify the
          applicable Supervisory Authority not later than 72 hours after having
          become aware of it, unless the personal data breach is unlikely to
          result in a risk to the rights and freedoms of natural persons.
        </p>
        <h3 id="11-controls-for-do-not-track-features">
          11. CONTROLS FOR DO-NOT-TRACK FEATURES
        </h3>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (“DNT”) feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. No uniform
          technology standard for recognizing and implementing DNT signals has
          been finalized. As such, we do not currently respond to DNT browser
          signals or any other mechanism that automatically communicates your
          choice not to be tracked online. If a standard for online tracking is
          adopted that we must follow in the future, we will inform you about
          that practice in a revised version of this privacy policy .
        </p>
        <h3 id="12-do-california-residents-have-specific-privacy-rights">
          12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h3>
        <p>
          In Short: Yes, if you are a resident of California, you are granted
          specific rights regarding access to your personal information.
        </p>
        <p>
          California Civil Code Section 1798.83, also known as the “Shine The
          Light” law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with the Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from our systems.
        </p>
        <h3 id="13-do-we-make-updates-to-this-policy">
          13. DO WE MAKE UPDATES TO THIS POLICY?
        </h3>
        <p>
          In Short: Yes, we will update this policy as necessary to stay
          compliant with relevant laws.
        </p>
        <p>
          We may update this privacy policy from time to time. The updated
          version will be indicated by an updated “Revised” date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy policy, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy policy
          frequently to be informed of how we are protecting your information.
        </p>
        <h3 id="14-how-can-you-contact-us-about-this-policy">
          14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
        </h3>
        <p>
          If you have questions or comments about this policy, you may contact
          our Data Protection Officer (DPO), Andricco Karoulla, by email at
          karoulla.andrico@gmail.com, or by post to:
        </p>
        <p>
          Andricco Karoulla <br />
          317 Brockley Road <br />
          London SE42QZ <br />
          United Kingdom
        </p>
      </div>
    </Layout>
  );
}

export default PrivacyPolicy;
